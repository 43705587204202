.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}

#admin-logotype {
    background-color: #fff;
    border-radius: 8px;
    margin: 8px;
    padding: 8px 8px 16px 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

a #admin-logotype, a:hover #admin-logotype, a:active #admin-logotype {
    color: #000;
}

.admin-table {
    width: 100%;
}

form.ant-form {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    background-color: #fafafa;
}

form.ant-form .ant-form-item:last-child {
    margin-bottom: 0;
}

.ant-layout-content > .ant-space {
    width: 100%;
}