.artdatabanken-observations-modal {
    width: 100% !important;
    max-width: 800px;
}

.artdatabanken-observations-modal * {
    font-size: 12px;
}

.small {
    font-size: 85%;
}

.icon-button-text {
    padding-left: 8px;
}