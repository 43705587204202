.share-modal {
    max-width: 800px;
}

.share-modal .url-container {
    display: flex;
    gap: 0.5em;
    align-items: center;
}

.share-modal .url-container > :first-child {
    flex: 1;
}
