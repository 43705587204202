#toolbar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
    padding: 0 6px 0 4px;
}

@media (max-width: 600px) {
    #toolbar {
        gap: 4px;
    }
}

#toolbar .separator {
    flex: 1;
}

#logotype {
    height: 32px;
    padding: 2px 4px;
    box-sizing: border-box;
}

.toolbar-dropdown-button {
    width: 24px;
    padding: 0 4px !important;
}

