.download-map-modal {
    max-width: 400px;
}

.download-map-modal .loading-modal-inner {
    width: 100%;
    display: flex;
    gap: 1.5em;
    justify-content: center;
}
