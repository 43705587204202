#menu {
    margin: 0.5em 1em;
}

#layer-group-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.layer-group-name {
    padding-left: 0.5em;
}

.layer-group-color-box {
    display: inline-block;
    width: 0.75em;
    height: 0.75em;
    border: 1px solid #000;
    border-radius: 3px;
}

.small-label {
    font-size: 75%;
    margin-bottom: 0.25em;
}

#background-map-selector-container {
    margin-bottom: 1em;
}

.background-map-selector-row {
    align-items: center;
}

.background-map-selector-row > :first-child {
    flex: 1 1 auto;
}
.background-map-selector-row > :last-child {
    flex: 0 0 auto;
    height: 32px;
    width: 32px !important;
}

#layer-group-list-container {
    margin-bottom: 1em;
}

#layer-group-list li {
    margin: 0.25em 0;
}

.layer-group-information-icon {
    display: inline-block;
    margin-left: 0.33em;
    opacity: 25%;
    cursor: pointer;
}

.layer-group-information-icon:hover {
    opacity: 50%;
}

.layer-group-description-modal {
    max-width: 1000px;
}

.layer-group-description-modal ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.layer-group-description-modal h4 {
    font-weight: bold;
}

.layer-group-description-modal p {
    margin-bottom: 1.5em;
}

.layer-group-subtitle {
    margin-left: 2.5em;
}

.layer-group-subtitle ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.layer-group-subtitle li {
    font-size: 80%;
}

.opacity-slider-container {
    margin-left: 2.0em;    
}

.background-map-selector-row {
    display: flex;
    gap: 8px;
    width: 100%;
}