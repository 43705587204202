/*
caption color: #415858
logotype color: #5E7F7F
*/

* {
    font-family: 'Helvetica', sans-serif;
    font-size: 14px;
}

.mobile-hidden {
    display: block;
}

.mobile-visible {
    display: none;
}

.mobile-only {
    display: none;
}

.desktop-only {
    display: block;
}

@media (max-width: 600px) {
    .mobile-only {
        display: block;
    }

    .desktop-only {
        display: none;
    }
}

@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}

.boolean-icon-true {
    color: green;
}

.boolean-icon-false {
    color: red;
}

.nowrap {
    white-space: nowrap;
}