#map-app {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

#main {
    position: absolute;
    top: 32px;
    left: 0;
    bottom: 0;
    right: 0;
}

#map-container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 25em;
}

#map {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    outline: none;
}

#menu-container {
    position: absolute;
    top: 0;
    width: 25em;
    bottom: 0;
    right: 0;
    background-color: #fff;
    overflow-y: scroll;
}

.current-position-icon {
    opacity: 0.85;
}

.artdatabanken-marker-icon {
    opacity: 0.85;
}

.leaflet-popup-content-wrapper {
    border-radius: 6px;
}

.overview-map {
    overflow: hidden;
    margin: 16px;
    border: 1px solid black;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 4px;
    padding: 8px;
}

.overview-map-inner {
    width: 100px;
    position: relative;
}

.overview-map img {
    width: 100%;
}

.overview-map .current-bounds {
    position: absolute;
    border: 1px solid #ff0000;
    background-color: rgba(255, 0, 0, 0.33);
}

.legend-square {
    width: 12px;
    height: 12px;
    border-radius: 3px;
    border: 1px solid #000;
    display: inline-block;
    margin-right: 6px;
}

.remaining-classification-data-1 {
    background-color: #53146e;
}
.remaining-classification-data-2 {
    background-color: #a72da3;
}
.remaining-classification-data-3 {
    background-color: #df4b83;
}

.executed-logging-classification-data-1 {
    background-color: #0019b3;
}
.executed-logging-classification-data-2 {
    background-color: #3955ff;
}
.executed-logging-classification-data-3 {
    background-color: #92a1fd;
}

.metadata-popup * {
    font-size: 12px; 
}

.metadata-popup-title-container {
    margin-bottom: 1em;
    display: flex;
    align-items: center;
    gap: 0.5em;
}

.metadata-popup-layer-group-legend {
    border: 1px solid #333;
    border-radius: 4px;
    height: 0.9em;
    width: 0.9em;
}

.metadata-popup-title {
    font-weight: bold;
}

.metadata-table tr th {
    padding-right: 1em;
}

.metadata-popup .navigation-buttons {
    margin-top: 1em;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.metadata-popup .navigation-buttons.single-area {
    display: none;
}

.artdatabanken-observation-popup .sighting-link {
    margin-top: 1em;
}

@media (max-width: 600px) {
    #map-container {
        right: 0;
        z-index: 0;
    }

    #menu-container {
        left: 20%;
        width: inherit;
        z-index: 1;
        display: none;
    }

    #menu-container.mobile-menu-visible {
        display: block;
    }

    .overview-map-inner {
        width: 50px;
    }

    .mobile-hidden {
        display: none;
    }
    .mobile-visible {
        display: block;
    }

    .leaflet-control-attribution, .leaflet-control-attribution * {
        font-size: 11px;
    }

    .leaflet-bottom .overview-map {
        margin-bottom: 20px;       
    }
}

@media print
{    
    #map-container {
        right: 0;
    }
}